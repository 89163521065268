import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';

import SecuredPage from '../sub-pages/SecuredPage';

import * as Paths from '../components/utils/Paths';

const App = () => (
  <Router>
    <PrivateRoute path={Paths.SECURED} component={SecuredPage} />
  </Router>
);

export default App;
