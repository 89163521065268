/* eslint-disable no-trailing-spaces, max-len */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Heading, Button } from 'rebass/styled-components';
import { navigate } from 'gatsby';

import Page from '../components/Page/Page';
import SEO from '../components/SEO/SEO';
import Text from '../components/Base/Text';
import { SignOutIcon } from '../components/Icons/Icons';

import background1 from '../images/background-1.jpeg';
import background2 from '../images/background-2.jpeg';
import background3 from '../images/background-3.jpeg';
import background4 from '../images/background-4.jpeg';

import * as Paths from '../components/utils/Paths';
import { logout } from '../components/utils/auth';

const BACKGROUNDS = [
  background1,
  background2,
  background3,
  background4,
];

const height = '3rem';

const Styles = {
  Image: styled(Box)(({ background }) => `
    position: fixed;
    top: ${height};
    left: 0;
    right: 0;
    bottom: 0;

    &::after {
      background: url(${background});
      content: "";
      // opacity: 0.8;
      opacity: 0.6;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 0; 
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;
      transition: background 1s linear;
    }
  `),
  ContentWrapper: styled(Box)`
    position: fixed;
    top: ${height};
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
  `,
  Header: styled(Box)(({ theme }) => `
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: ${height};
    background-color: ${theme.colors.background};
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 1rem;
  `),
  Logout: styled.a(({ theme }) => `
    color: ${theme.colors.text};
    &:hover {
      color: ${theme.colors.primary};
      cursor: pointer;
    }
  `),
  LogoutIcon: styled.span`
    margin-left: 0.5rem;
    display: inline-block;
  `,
  Content: styled(Box)(({ theme }) => `
    margin: 0 auto;
    padding: 8rem 2rem 0;
    text-align: center;
    max-width: 24rem;
    flex-shrink: 1;
    flex-grow: 1;

    @media screen and (min-width: ${theme.breakpoints[2]}) {
      max-width: 34rem;
    }

    @media screen and (min-width: ${theme.breakpoints[4]}) {
      max-width: 40rem;
    }

    @media screen and (min-width: ${theme.breakpoints[5]}) {
      max-width: 46rem;
    }
  `),
  Footer: styled.footer`
    text-align: center;
    margin: 1.5rem 0 0.75rem;
    font-size: 1.1rem;
    padding: 0 2rem;
  `,
  Link: styled.a(({ theme }) => `
    color: ${theme.colors.text};
    &:hover {
      color: ${theme.colors.primary};
      cursor: pointer;
    }
  `),
  Heading: styled(Heading)`
    margin-bottom: 4rem;
  `,
  Text: styled(Text)`
    margin: 4rem 0;
    font-weight: 600;
    hyphens: auto; 
    text-align: justify;
  `,
  Ja: styled(Text)(({ theme }) => `
    font-weight: 900;
    margin: 2.5rem 0;
    font-size: 5rem;
    color: ${theme.colors.primary};
    text-align: center;
  `),
  Button: styled(Button)`
    margin: 2rem 0;
  `,
};

const SecuredPage2 = () => {
  const [index, setIndex] = useState(0);

  const handleLogout = () => {
    logout();
    navigate(Paths.INDEX);
  };

  const handleOnClick = () => {
    if (index === BACKGROUNDS.length - 1) {
      setIndex(0);
    } else {
      setIndex((prev) => prev + 1);
    }
  };

  const fontSizes = [4, 4, 5];

  return (
    <Page>
      <SEO title="Familie Liistro" />
      <Styles.Image background={BACKGROUNDS[index]} />
      <Styles.Header>
        <Text>
          liistro.ch
        </Text>
        <Styles.Logout onClick={handleLogout}>
          Logout
          <Styles.LogoutIcon>
            <SignOutIcon />
          </Styles.LogoutIcon>
        </Styles.Logout>
      </Styles.Header>
      <Styles.ContentWrapper>
        <Styles.Content>
          <Styles.Heading fontSize={[6, 6, 7]}>Willkommen auf der Seite der jungen Familie Liistro</Styles.Heading>
          <Styles.Text fontSize={fontSizes}>
            Die Idee für die Location sowie die Art und Weise war schon lange vorhanden und wartete nur um Umgesetzt zu werden.
            Nach unserem Japan Urlaub war es dann endlich soweit und die ersten Schritte (Flug, Hotel, Ring) wurden eingeleitet.
          </Styles.Text>
          <Styles.Text fontSize={fontSizes}>
            Dieser Februar war es dann endlich soweit und die Frage aller Fragen konnte im hohen & kalten Norden gestellt werden...
            <br />
            Da ihr diesen Text nun lesen könnt hat meine Traumfrau mit
            <Styles.Ja fontSize={fontSizes}>JA</Styles.Ja>
            auf meine Frage geantwortet und mich somit zum glücklisten Mann gemacht.
          </Styles.Text>
          <Styles.Button
            type="button"
            onClick={handleOnClick}
          >
            Nächstes Bild
          </Styles.Button>
        </Styles.Content>
        <Styles.Footer>
          Erstellt von
          {' '}
          <Styles.Link
            href="http://andreasgasser.com"
            target="_blank"
          >
            Andreas Gasser
          </Styles.Link>
        </Styles.Footer>
      </Styles.ContentWrapper>
    </Page>
  );
};

export default SecuredPage2;
